<template>
    <v-container fluid>
        <v-card flat max-width="350">
            <v-alert v-model="hasErrors" type="error" color="red lighten-2">{{ error }}</v-alert>
            <v-alert v-model="isSuccess" type="success" color="primary">Passwort erfolgreich geändert!</v-alert>
            <v-card-title>Passwort ändern</v-card-title>
            <v-card-text>
                <v-text-field type="password" v-model="password" placeholder="Neues Passwort" />
            </v-card-text>
            <v-card-text>
                <v-text-field type="password" v-model="passwordConfirm" placeholder="Neues Passwort bestätigen" />
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn color="primary" @click.stop="changePassword()" elevation="0">Neues Passwort speichern</v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { AccountService } from "@/modules/store/account/services/account.service"

export default {
    data: () => ({
        password: "",
        passwordConfirm: "",
        hasErrors: false,
        error: "",
        isSuccess: false
    }),

    computed: {
        ...mapGetters("auth", ["userId"])
    },

    methods: {
        ...mapActions('auth', ['setToken']),

        changePassword() {
            this.hasErrors = false;
            this.error = "";

            if (!this.password) {
                this.hasErrors = true;
                this.error = "Bitte ein Passwort angeben!";

                return false;                
            }

            if (this.password != this.passwordConfirm) {
                this.hasErrors = true;
                this.error = "Passwörter stimmen nicht überein!";

                return false;
            }

            const params = {
                newPassword: this.password,
                accountId: this.userId
            }

            AccountService.changePassword(params)
                .then((res) => { 
                    if (!res?.token) {
                        this.hasErrors = true;
                        this.error = "Da ist etwas schiefgelaufen. Bitte versuchen Sie es erneut.";

                        return false;
                    }
                    
                    this.setToken(res.token);
                    this.isSuccess = true;
                })
        }
    }
}
</script>

<style>

</style>